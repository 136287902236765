<template>
  <div id="zqHostingOptions">
    <ClFormRow :label="'Index File Name'" >
      <template #content>
        <CInput
          v-model="indexFileName"
          name="indexFileName"
          :placeholder="'Index File Name'"
          :disabled="disabled"
          ref="indexFileName"
          add-input-classes="col-sm-12"
        />
      </template>
    </ClFormRow>
    <ClFormRow :label="'Error File Name'">
      <template #content>
        <CInput
          v-model="errorFileName"
          name="errorFileName"
          :placeholder="'Error File Name'"
          :disabled="disabled"
          ref="errorFileName"
          add-input-classes="col-sm-12"
        />
      </template>
    </ClFormRow>
  </div>
</template>

<script>
export default {
  name: "ZqHostingOptionsFields",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hostingOptions: {
        indexFileName: '',
        errorFileName: ''
      },
      indexFileName: '',
      errorFileName: ''
    }
  },
  created() {
    this.$emit('input', { indexFileName: this.indexFileName, errorFileName: this.errorFileName });
  },
  watch: {
    indexFileName(val) {
      this.hostingOptions.indexFileName = val

      if (val) {
        this.$refs.indexFileName.$el.classList.remove('zq-invalid');
      }
    },
    errorFileName(val) {
      this.hostingOptions.errorFileName = val

      if (val) {
        this.$refs.errorFileName.$el.classList.remove('zq-invalid');
      }
    },
    hostingOptions: {
      deep: true,
      handler(val) {
        this.$emit('input', val);
      }
    }
  },
}
</script>

<style scoped>

</style>